.movie-thumbnail {
    position: relative;
    height: 8rem;
    width: 12.5rem;
    background-size: cover;
    transition: all .25s ease-out;
    margin: 0 .35rem;
}

.movie-thumbnail:hover {
    transform: translateY(-.5rem);
    cursor: pointer;
}

.movie-thumbnail__background {
    background-color: rgba(0, 0, 0, 50%);
    height: 20%;
    width: 100%;
    padding: .5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: none;
}

.movie-thumbnail__text {
    color: white;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 20rem;
    outline: none;
}

@media only screen and (max-width: 1120px) {
   .movie-thumbnail {
       width: 10rem;
       height: 6rem;
   }
}

@media only screen and (max-width: 920px) {
    .movie-thumbnail {
        width: 8rem;
        height: 5rem;
    }
}

@media only screen and (max-width: 370px) {
    .movie-thumbnail {
        width: 5.5rem;
        height: 3.5rem;
    }
}

@media only screen and (max-width: 320px) {
    .movie-thumbnail {
        width: 5rem;
        height: 3.5rem;
    }
}
