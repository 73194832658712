.movies-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.movies-list__arrow {
    height: 2rem;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

@media only screen and (max-width: 920px) {
    .movies-list__arrow {
        height: 1.5rem;
    }
}

@media only screen and (max-width: 620px) {
    .movies-list__arrow {
        height: 1.25rem;
    }
}

@media only screen and (max-width: 320px) {
    .movies-list__arrow {
        height: 1rem;
    }
}
