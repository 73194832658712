.main-movie {
    margin-bottom: 1rem;
}

.main-movie__video {
    height: 35rem;
    width: 65rem;
}

@media only screen and (max-width: 1120px) {
    .main-movie__video {
        height: 25rem;
        width: 55rem;
    }
    
    .main-movie {
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 920px) {
    .main-movie__video {
        height: 25rem;
        width: 45rem;
    }
    
    .main-movie {
        margin-bottom: 0.8rem;
    }
}

@media only screen and (max-width: 920px) {
    .main-movie__video {
        height: 22.5rem;
        width: 37.5rem;
    }
}

@media only screen and (max-width: 620px) {
    .main-movie__video {
        height: 20rem;
        width: 31.5rem;
    }
}

@media only screen and (max-width: 520px) {
    .main-movie__video {
        height: 17rem;
        width: 28rem;
    }
}

@media only screen and (max-width: 455px) {
    .main-movie__video {
        height: 15rem;
        width: 26rem;
    }
}

@media only screen and (max-width: 455px) {
    .main-movie__video {
        height: 13rem;
        width: 24rem;
    }
}

@media only screen and (max-width: 410px) {
    .main-movie__video {
        height: 12.5rem;
        width: 22.5rem;
    }
}

@media only screen and (max-width: 365px) {
    .main-movie__video {
        height: 11rem;
        width: 20rem;
    }
}