.container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(150deg, #08040D 0%, #15131D 65%);
}

.app-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

@media only screen and (max-width: 1120px) {
  .app-container {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 620px) {
  .app-container {
    justify-content: flex-start;
    padding: 3rem 0;
  }
}

.loading-container {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(150deg, #08040D 0%, #15131D 65%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: all .5s;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: all .5s;
}

.loading-list {
  display: flex;
  flex-direction: column;
  color: white;
}

.loading-list__item {
  font-size: 3rem;
  text-align: center;
  font-weight: 200;
}

@media only screen and (max-width: 500px) {
  .loading-list__item {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .loading-list__item {
    font-size: 2rem;
  }
}

.loading-logo {
  width: 50rem;
  margin-bottom: 5rem;
}

@media only screen and (max-width: 800px) {
  .loading-logo {
    width: 30rem;
  }
}

@media only screen and (max-width: 500px) {
  .loading-logo {
    width: 25rem;
  }
}

@media only screen and (max-width: 400px) {
  .loading-logo {
    width: 20rem;
  }
}

.movies-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.movies-container__cta {
  color: white;
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 0.5rem;
}

@media only screen and (max-width: 620px) {
  .movies-container {
      margin-bottom: 1.5rem;
  }
}

.logo-container__logo {
  width: 18rem;
}

@media only screen and (max-width: 920px) {
  .logo-container__logo {
    width: 16rem;
  }
}

@media only screen and (max-width: 620px) {
  .logo-container__logo {
    width: 14rem;
    margin-bottom: 1.5rem;
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1300px) {
  .footer {
    padding: 0rem 1.5rem;
  }
}

.icons {
  display: flex;
  flex: 1 1 0px;
}

@media only screen and (max-width: 700px) {
  .icons {
    flex: 1 1 50%;
  }
}

.icons--icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 920px) {
  .icons--icon {
    width: 2rem;
    height: 2rem;
  }
}

@media only screen and (max-width: 385px) {
  .icons--icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.copyright {
  font-size: 1.25rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 1 0px;
}

.copyright--creator {
  font-size: 0.75rem;
  font-weight: 200;
}

@media only screen and (max-width: 1120px) {
  .copyright {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 920px) {
  .copyright {
    font-size: 0.75rem;
  }

  .copyright--creator {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 700px) {
  .copyright {
    font-size: 0.6rem;
    flex: 0 0 100%;
    align-items: center;
    margin-top: 1rem;
  }

  .copyright--creator {
    font-size: 0.5rem;
  }
}

.contact {
  color: white;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 0px;
}

@media only screen and (max-width: 1300px) {
  .contact {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 1120px) {
  .contact {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 920px) {
  .contact {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 385px) {
  .contact {
    font-size: 0.5rem;
  }
}

.contact--label {
  font-weight: 400;
  margin-right: .5rem;
}

.contact--text {
  font-weight: 200;
}

.contact__email {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;
}

.contact__phone {
  display: flex;
  align-items: center;
}